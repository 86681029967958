<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">Lavorare per apportare cambiamenti significativi nell'apprendimento online facendo ricerche approfondite per la preparazione del curriculum del corso, il coinvolgimento degli studenti e aspettando con impazienza l'istruzione flessibile!</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/isfondazionedoria" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/doriaistituto" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/isfondazionedoria" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/in/istituto-scolastico-fondazione-doria-85b3611b9/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCcayjtlx0U_dawO4fk4t2_w" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://discentya.istitutofondazionedoria.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://discentya.istitutofondazionedoria.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Cupa Mastrogennaro, 11<br>84012 Angri (SA) - Italia</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390813503322">Telefono: (+39) 081-3503322</a></li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390813503322">Telefono: (+39) 081-19230373</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info.fondazionedoria@gmail.com">email: info.fondazionedoria@gmail.com</a></li>
                        <li><i class='bx bxs-inbox'></i>
                            <a href="javascript:;">Lunedì-Venerdì: 09:00÷18:00<br>Sabato: 09:00÷13:00</a>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2022 Istituto Fondazione Doria - realizzato con <a style="color: #ffe44c;" target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul>
                        <li><a target="_blank" href="https://www.istitutodoria.it/privacy-policy">Privacy Policy</a></li>
                        <li><a target="_blank" href="https://www.istitutodoria.it/cookie-policy">Cookie Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
